<template>
  <v-dialog v-model="dialogNewSubService" max-width="434px">
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        v-bind:style="[
          {
            position: absolute ? 'absolute' : 'relative',
            right: absolute ? (first ? '20px' : '95px') : '',
          },
        ]"
      >
        <v-icon large>mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>{{ $t("new-subservice") }}</span>
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialogNewSubService = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="sub-service-form"
        @submit.prevent="validateForm('sub-service-form')"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <label>{{ $t("services.description") }}</label>
              <v-text-field
                v-model="newSubservice.description"
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('sub-service-form.description')"
                data-vv-name="description"
                hide-details
                size="15"
                style="padding-top: 0 !important"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <label>{{ $t("services.time") }}</label>
              <v-text-field
                v-model="newSubservice.time"
                outlined
                type="number"
                dense
                data-vv-validate-on="blur"
                v-validate="''"
                :error-messages="errors.collect('sub-service-form.time')"
                data-vv-name="time"
                hide-details
                size="15"
                style="padding-top: 0 !important"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <label>{{ $t("services.sesions") }}</label>
              <v-text-field
                v-model="newSubservice.sesions"
                outlined
                type="number"
                dense
                data-vv-validate-on="blur"
                v-validate="''"
                :error-messages="errors.collect('sub-service-form.sesions')"
                data-vv-name="sesions"
                hide-details
                size="15"
                style="padding-top: 0 !important"
              ></v-text-field>
            </v-col>
            <template v-if="service.id == 1 || service.id == 4">
              <v-col cols="12" sm="6" class="py-0">
                <label>{{ $t("services.body_part") }}</label>
                <v-select
                  v-model="newSubservice.body_part"
                  hide-details
                  outlined
                  dense
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  :error-messages="errors.collect('sub-service-form.body_part')"
                  data-vv-name="body_part"
                  :items="body_parts"
                  @change="newSubservice.sub_body_part = 'all'"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <label>{{ $t("services.sub_body_part") }}</label>
                <v-select
                  v-model="newSubservice.sub_body_part"
                  hide-details
                  outlined
                  dense
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  :error-messages="
                    errors.collect('sub-service-form.sub_body_part')
                  "
                  data-vv-name="sub_body_part"
                  :items="sub_body_parts"
                ></v-select>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" class="py-0">
                <label>{{ $t("services.body_part") }}</label>
                <v-autocomplete
                  v-model="newSubservice.sub_body_part"
                  hide-details
                  outlined
                  dense
                  data-vv-validate-on="blur"
                  v-validate="''"
                  :error-messages="
                    errors.collect('sub-service-form.sub_body_part')
                  "
                  data-vv-name="sub_body_part"
                  :items="sub_body_parts"
                  @change="changeBodyPart"
                ></v-autocomplete>
              </v-col>
            </template>
            <v-col cols="12" sm="6" class="py-0">
              <label>{{ $t("services.price") }}</label>
              <v-text-field
                v-model="newSubservice.price"
                outlined
                dense
                type="number"
                data-vv-validate-on="blur"
                v-validate="''"
                :error-messages="errors.collect('sub-service-form.price')"
                data-vv-name="price"
                hide-details
                size="15"
                style="padding-top: 0 !important"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="py-0">
              <label>{{ $t("services.paymentSignalPrice") }}</label>
              <v-text-field
                v-model="newSubservice.paymentSignalPrice"
                outlined
                dense
                type="text"
                data-vv-validate-on="blur"
                v-validate="''"
                :error-messages="
                  errors.collect('sub-service-form.paymentSignalPrice')
                "
                data-vv-name="paymentSignalPrice"
                hide-details
                size="15"
                style="padding-top: 0 !important"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <label>{{ $t("services.marketplace") }}</label>
              <v-select
                :items="options"
                v-model="newSubservice.marketplace"
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="errors.collect('sub-service-form.marketplace')"
                hide-details
                size="15"
                style="padding-top: 0 !important"
                data-vv-name="marketplace"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <label>{{ $t("services.image") }}</label>
              <v-file-input
                prepend-icon
                hide-details
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="''"
                :error-messages="errors.collect('sub-service-form.file')"
                data-vv-name="file"
                v-model="newSubservice.file"
              ></v-file-input>
            </v-col>
            <v-col cols="12" class="py-0" v-if="newSubservice.marketplace">
              <label>{{ $t("services.marketplace_pay") }}</label>
              <v-select
                :items="options_pay"
                v-model="newSubservice.marketplace_pay"
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="'required'"
                :error-messages="
                  errors.collect('sub-service-form.marketplace_pay')
                "
                data-vv-name="marketplace_pay"
                hide-details
                size="15"
                style="padding-top: 0 !important"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="close" style="height: 25px" elevation="0">{{
            $t("cancel")
          }}</v-btn>
          <v-btn
            style="color: #363533; height: 25px; padding-top: 10px !important"
            elevation="0"
            type="submit"
            @click="save"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: "" }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NewSubServiceButton",
  props: ["service"],
  data() {
    return {
      options: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
      dialogNewSubService: false,
      services: [],
      newIndex: -1,
      newSubservice: {
        description: "",
        time: null,
        sesions: null,
        price: null,
        obligatoryPayment: null,
        paymentSignal: 0,
        paymentSignalPrice: null,
        marketplace: null,
        parent_id: 0,
        body_part: "all",
        sub_body_part: "all",
      },
      defaultItem: {
        description: "",
        time: null,
        sesions: null,
        price: null,
        obligatoryPayment: null,
        paymentSignal: 0,
        paymentSignalPrice: null,
        marketplace: null,
        parent_id: 0,
        body_part: "all",
        sub_body_part: "all",
      },
    };
  },

  methods: {
    changeBodyPart(sub_body_part) {
      let sbp = this.sub_body_parts.find((sbp) => sub_body_part == sbp.value);
      console.log(sbp);
      this.newSubservice.body_part = sbp.main;
    },
    ...mapActions("subservices", ["addSubService"]),

    close() {
      this.dialogNewSubService = false;
      this.$nextTick(() => {
        this.newSubservice = Object.assign({}, this.defaultItem);
        this.newIndex = -1;
        console.log(this.newSubservice);
        console.log(this.service.id);
      });
    },
    async save() {
      this.errors.clear();
      let result = await this.$validator.validateAll("sub-service-form");
      if (result)
        if (this.newIndex > -1) {
          Object.assign(this.services[this.newIndex], this.newSubservice);
        } else {
          this.newSubservice.parent_id = this.service.id;
          let formData = new FormData();
          Object.keys(this.newSubservice).forEach((key) => {
            if (this.newSubservice[key] !== null)
              formData.append(key, this.newSubservice[key]);
          });
          this.addSubService({
            service_id: this.service.id,
            subservice: formData,
          }).then((response) => {
            console.log(this.newSubservice);

            if (response) {
              this.$alert(this.$t("save_ok"));
              this.dialogNewSubService = false;
              this.$emit("update");
            }
          });
        }
    },
  },
  computed: {
    options_pay() {
      return [
        { value: "nothing", text: this.$t("services.marketplaces.nothing") },
        {
          value: "token_payment",
          text: this.$t("services.marketplaces.token_payment", {
            price:
              this.newSubservice.paymentSignalPrice > 0
                ? this.$n(this.newSubservice.paymentSignalPrice, "currency")
                : null,
          }),
        },
        {
          value: "price",
          text: this.$t("services.marketplaces.price", {
            price:
              this.newSubservice.price * this.newSubservice.sesions > 0
                ? this.$n(
                    this.newSubservice.price * this.newSubservice.sesions,
                    "currency"
                  )
                : null,
          }),
        },
      ];
    },
    body_parts() {
      let items = [
        { text: "Todas", value: "all" },
        { text: "Cabeza", value: "cabeza" },
        { text: "Tronco", value: "tronco" },
        { text: "Brazo", value: "brazo" },
        { text: "Pierna", value: "pierna" },
      ];
      return items;
    },
    sub_body_parts() {
      if (this.service.id != 2) {
        switch (this.newSubservice.body_part) {
          case "cabeza":
            return [
              { text: this.$t("Todas"), value: "all" },
              {
                text: this.$t("body.face"),
                value: "cara",
              },
              {
                text: this.$t("body.neck"),
                value: "cuello",
              },
            ];
          case "brazo":
            return [
              { text: this.$t("Todas"), value: "all" },
              {
                text: this.$t("body.forearm"),
                value: "antebrazo",
              },
              {
                text: this.$t("body.full_arm"),
                value: "todo_brazo",
              },
              { text: this.$t("body.wrist"), value: "muneca" },

              { text: this.$t("body.biceps"), value: "biceps" },

              { text: this.$t("body.hand"), value: "mano" },
            ];
          case "tronco":
            return [
              { text: this.$t("Todas"), value: "all" },
              {
                text: this.$t("body.right_pectoral"),
                value: "right_pectoral",
              },
              {
                text: this.$t("body.left_pectoral"),
                value: "left_pectoral",
              },

              {
                text: this.$t("body.abdomen"),
                value: "abdomen",
              },
              {
                text: this.$t("body.full_back"),
                value: "full_back",
              },
            ];
          case "pierna":
            return [
              { text: this.$t("Todas"), value: "all" },
              { text: this.$t("body.thigh"), value: "muslo" },
              { text: this.$t("body.calf"), value: "gemelo" },
              { text: this.$t("body.knee"), value: "gemelo" },
              { text: this.$t("body.ankle"), value: "tobillo" },
            ];
          default:
            return [{ text: this.$t("Todas"), value: "all" }];
        }
      } else {
        return [
          {
            text: this.$tc("body.all"),
            value: "all",
            main: "all",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.brow"),
            value: "brow",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.bridge"),
            value: "bridge",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.nose"),
            value: "nose",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.septum"),
            value: "septum",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.superior_lip"),
            value: "superior_lip",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.corner_lip"),
            value: "corner_lip",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.inferior_lip"),
            value: "inferior_lip",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.tongue"),
            value: "tongue",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.cheek"),
            value: "cheek",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.industrial"),
            value: "industrial",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.lobe"),
            value: "lobe",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.cartilage"),
            value: "cartilage",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.tragus"),
            value: "tragus",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.daith"),
            value: "daith",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.rook"),
            value: "rook",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.conch"),
            value: "conch",
            main: "facePiercing",
          },
          {
            text: "Cara - " + this.$tc("facePiercing.microdermal"),
            value: "microdermal",
            main: "facePiercing",
          },

          {
            text: "Cuerpo - " + this.$tc("bodyPiercing.nipple"),
            value: "nipple",
            main: "bodyPiercing",
          },

          {
            text: "Cuerpo - " + this.$tc("bodyPiercing.navel"),
            value: "navel",
            main: "bodyPiercing",
          },

          {
            text: "Cuerpo - " + this.$tc("bodyPiercing.genitals"),
            value: "genitals",
            main: "bodyPiercing",
          },

          {
            text: "Cuerpo - " + this.$tc("bodyPiercing.surface"),
            value: "surface",
            main: "bodyPiercing",
          },
        ];
      }
    },
  },
};
</script>

<style></style>
